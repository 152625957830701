import { NotFoundPage, getProperty } from "@gh/shared";

import { useMeta, usePageByView } from "hooks";

const Template = () => {
  const meta = useMeta();
  const page = usePageByView("home");
  const path = getProperty(page, "path");

  const title = getProperty(meta, "meta.error.title");
  const text = getProperty(meta, "meta.error.content");
  const button = getProperty(meta, "meta.navigation.label_back_home");

  return <NotFoundPage title={title} text={text} button={button} backPath={path} />;
};

export default Template;
